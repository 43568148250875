import styled from 'styled-components'

export const Container = styled.section`
  min-height: 400px;
  margin-left: 40px;

  h1 {
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 0;
  }
`

export const Form = styled.div`
  background: #f7f7f7;
  margin: 20px 0 30px;
  max-width: 1000px;
  padding: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    background-color: ${({ theme }) => theme.colors.primary_0};
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    font-weight: 700;
    padding: 12px 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    transition: 0.3s;
    height: 55px;
  }

  button:hover {
    background-color: ${({ theme }) => theme.colors.primary_1};
  }
`
export const FormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  input,
  select,
  textarea {
    border: 1px solid #d3d3d3;
    font-size: 16px;
    opacity: 1;
    padding: 14px 19px;
    text-align: left;
    width: 100%;
    background: #fff;
    margin-top: 1%;

    &::placeholder {
      font-size: 14px;
      color: #8b8888;
    }
  }
`
