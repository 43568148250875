import { gql } from '@faststore/graphql-utils'

import type {
  SendContactMessageMutation as Mutation,
  SendContactMessageMutationVariables as Variables,
} from '../../@generated/graphql/index'
import { useLazyQuery } from '../sdk/graphql/useLazyQuery'

export const mutation = gql`
  mutation SendContactMessage($data: IContactDTO!) {
    sendContactMessage(data: $data) {
      id
    }
  }
`

export const useContact = () => {
  const [sendContactMessage, { data, error, isValidating: loading }] =
    useLazyQuery<Mutation, Variables>(mutation, {
      data: {
        email: '',
        name: '',
        lastname: '',
        phone: '',
        subject: '',
        message: '',
        cpf: '',
        order: '',
      },
    })

  return {
    sendContactMessage,
    data,
    error,
    loading,
  }
}
