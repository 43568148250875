import { useCallback, useState } from 'react'
import { useContact } from 'src/hooks/useContact'
import useForm from 'src/hooks/useForm'
import { cpfMask, phoneMask } from 'src/utils/masks'

import * as S from './styles'

const ContactUs: FCC = () => {
  const { data: formData, handleInputChange, handleInputMaskChange } = useForm()
  const [status, setStatus] = useState('')

  const { sendContactMessage } = useContact()

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      try {
        setStatus('Enviando mensagem...')
        await sendContactMessage({ data: formData })
        setStatus('Mensagem enviada com sucesso!')
      } catch (err) {
        setStatus('Ocorreu um erro :(')
      }

      setTimeout(() => setStatus(''), 5000)
    },
    [formData, sendContactMessage]
  )

  return (
    <S.Container>
      <h1>Fale conosco</h1>
      <p>Seu contato é muito importante para nós.</p>
      <p>
        Envie-nos um e-mail preenchendo os campos abaixo ou se preferir ligue
        (11) 4810-6810.
      </p>
      <S.Form>
        <form onSubmit={handleSubmit}>
          <S.FormItem>
            <label htmlFor="name">Nome</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Nome"
              required
              onChange={handleInputChange}
            />
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="lastname">Sobrenome</label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              placeholder="Sobrenome"
              required
              onChange={handleInputChange}
            />
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="E-mail"
              required
              onChange={handleInputChange}
            />
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="cpf">CPF</label>
            <input
              type="text"
              id="cpf"
              name="cpf"
              placeholder="CPF"
              maxLength={14}
              required
              onChange={(e) => handleInputMaskChange(e, cpfMask)}
            />
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="order">Pedido</label>
            <input
              type="text"
              id="order"
              name="order"
              placeholder="Pedido"
              maxLength={16}
              onChange={handleInputChange}
            />
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="phone">Telefone</label>
            <input
              id="phone"
              name="phone"
              placeholder="Telefone"
              type="tel"
              maxLength={15}
              required
              onChange={(e) => handleInputMaskChange(e, phoneMask)}
            />
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="subject">Assunto</label>
            <select
              id="subject"
              placeholder="Assunto"
              name="subject"
              required
              onChange={handleInputChange}
            >
              <option value="">Selecione</option>
              <option value="Críticas ou sugestões">
                Críticas ou sugestões
              </option>
              <option value="Elogios e agradecimentos">
                Elogios e agradecimentos
              </option>
              <option value="Dúvidas sobre pedido, pagamento ou prazo de entrega">
                Dúvidas sobre pedido, pagamento ou prazo de entrega
              </option>
              <option value="Propostas comerciais">Propostas comerciais</option>
              <option value="Parcerias na internet">
                Parcerias na internet
              </option>
              <option value="Cancelamento de Pedido">
                Cancelamento de Pedido
              </option>
              <option value="Outros">Outros</option>
            </select>
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="message">Mensagem</label>
            <textarea
              id="message"
              name="message"
              placeholder="Deixe sua mensagem..."
              required
              onChange={handleInputChange}
            />
          </S.FormItem>
          <button type="submit">Enviar</button>
        </form>
        <p>{!!status && status}</p>
      </S.Form>
    </S.Container>
  )
}

export default ContactUs
